import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';
import { DEFAULT_RIBBON_TEXT } from '../../constants/app';

export default createBlocksModel({
  widgetName: 'Ribbon',
  props: {
    text: {
      defaultValue: DEFAULT_RIBBON_TEXT,
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {
    getTextElement: {
      returnsDescription: 'Text element',
    },
  },
  events: {},
});
