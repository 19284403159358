import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { ElementRole } from '../../constants/elements';
import model from './model';

enum StateId {
  // TODO: Fix typo in widget editor
  Plan = 'defualt',
  Spinner = 'loading',
  Empty = 'empty',
}

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { planId } = $widget.props;

  const fetchPlan = async (): Promise<PublicPlan | null> => {
    // TODO: Use warmup data
    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds: [planId] }));
    return response.data.plans?.[0] ?? null;
  };

  const setPlan = async () => {
    // Show default demo plan in editor if plan was never set
    if (flowAPI.environment.isEditor && !planId) {
      return;
    }

    if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
      showSpinner();
    }

    const plan = await fetchPlan();
    if (!plan) {
      showEmptyState();
    } else {
      await $w(`#${ElementRole.PlanWidget}`).setPlan(plan);
      showLoadedState();
    }
  };

  const showSpinner = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Spinner);
  };

  const showEmptyState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Empty);
  };

  const showLoadedState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Plan);
  };

  return {
    pageReady: () => {
      return setPlan();
    },
    exports: {},
  };
});
